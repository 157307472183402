@import "../PageDashboard.scss";
@import "../PageDashboard.scss";
@import "../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";

.compliance-container {
  @include PageDashboard();

  .view-profile {
    @include text(500, 14px, 16px, $lightBlue);
    letter-spacing: 0.4px;
    cursor: pointer;
  }

  .profile-detail-content {
    min-height: 100vh;

    .go-back {
      cursor: pointer;
      margin: 24px 0 16px 0;
      @include text(600, 14px, 16px, $lightBlue);
    }
    .contents {
      background-color: $white;
      padding-bottom: 50px;
    }
    .people-profile {
      padding: 24px;
      .name {
        @include text(400, 14px, 16px, $spn-700-color);
      }
      .desc,
      .desc-docs {
        border: 1px solid $offWhite;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        align-items: center;
        height: 48px;
        margin: 8px 0 24px 0;
        @include text(400, 16px, 16px, $spn-700-color);
      }
      .desc-docs {
        display: flex;
        justify-content: space-between;

        .doc-img {
          margin-right: 12px;
        }
        .view-file {
          cursor: pointer;
          @include text(400, 16px, 16px, $lightBlue);
        }
      }
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tab-content {
      border-bottom: 1px solid $offWhite;
      display: flex;
      padding-bottom: 0;
      padding-left: 32px;

      .active-tab,
      .inactive-tab {
        display: inline-block;
        margin-right: 24px;
        cursor: pointer;
      }
      .active-tab {
        @include text(500, 14px, 16px, $lightBlue);
        border-bottom: 2px solid $lightBlue;
        padding-bottom: 16px;
      }
      .inactive-tab {
        @include text(500, 14px, 16px, $spn-500-color);
      }
    }
    .profile-box {
      border: 1px solid $offWhite;
      border-radius: 3px;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px 0 26px 0;
      margin-top: 24px;
      height: 280px;
      background-color: $white;
      .profile-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .edit-item {
        height: 24px;
        width: 24px;
        background: $spn-050-color;
        border: 1px solid $offWhite;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
      }
      .view-profile {
        background: $white;
        border: 1px solid $lightBlue;
        border-radius: 4px;
        height: 40px;
        width: 180px;
        margin-top: 18px;
        cursor: pointer;
        @include text(600, 16px, 24px, $lightBlue);
      }
      .profile-img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background-color: $spb-100-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        @include text(400, 24px, 32px, black);
      }

      .email {
        margin: 8px 0 10px 0;
        @include text(500, 12px, 16px, $appGrey);
      }
      .approve,
      .pending,
      .review {
        border-radius: 3px;
        display: inline-block;
        text-align: center;
        padding: 4px 7px;
      }
      .review {
        background: $spo-100-color;
        @include text(400, 10px, 16px, $spo-400-color);
      }
      .approve {
        background: rgba(65, 201, 119, 0.3);
        @include text(400, 10px, 16px, $spg-900-color);
      }
      .pending {
        background: $spo-100-color;
        @include text(400, 10px, 16px, $spo-400-color);
      }
      .view-accept-action {
        display: none;
      }
    }

    .profile-box:hover {
      .profile-info {
        display: none;
      }

      .view-accept-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .view,
        .accept {
          background: $white;
          border: 1px solid $lightBlue;
          border-radius: 4px;
          height: 40px;
          width: 180px;
          margin-top: 18px;
          cursor: pointer;
        }
        .accept {
          background: $lightBlue;
          display: flex;
          align-items: center;
          justify-content: center;
          @include text(600, 16px, 24px, $white);
        }
        .view {
          @include text(600, 16px, 24px, $lightBlue);
        }
      }
    }
    .submit-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .cancel,
      .submit {
        background: $white;
        border: 1px solid $white;
        border-radius: 4px;
        height: 40px;
        width: 180px;
        margin-top: 18px;
        cursor: pointer;
      }
      .submit {
        background: $lightBlue;
        @include text(600, 16px, 24px, $white);

        &:disabled {
          background: $appGrey !important;
          cursor: not-allowed;
        }
      }
      .cancel {
        @include text(600, 16px, 24px, $lightBlue);
      }
    }
  }
}
