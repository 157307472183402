.filter-menu-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
   
    .create-btn {
        height: 40px;
        margin-top: 10px;
    }
}