@import "../../../../assets/common/colors.scss";

.code-wrapper{
    background:$navyBlue;
    width: 100%;
    padding: 10px;
}

.form-container{
    padding: 30px;
    .get-Transaction{
        border: solid 1px $offWhite;
        padding: 20px;
        border-radius: 3px;
        width: 100%;
        max-width: 400px;
        margin: auto;
        .form-header h4{
            font-weight: 700;
        }
    }
    .datepicker{
        border: 1px solid $offWhite;
    }
}
