@import "../PageDashboard.scss";
@import "../PageDashboard.scss";
@import "../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";
.currency-container {
  @include PageDashboard();
  .tab-content {
    border-bottom: 1px solid $offWhite;
    display: flex;
    padding-bottom: 0;
    padding-left: 32px;
  }
}
