@import "../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";

.view-container {
   .key {
    padding-top: 16px;
     @include text(600, 12px, 16px, $spn-500-color);
  } 
   .value {
     padding: 16px 0 24px 0;
     @include text(400, 16px, 16px, $spn-900-color);
     border-bottom: 1px solid $spn-050-color;
  }

  .close{
    cursor: pointer;
    float: right;
    margin-top: 5px;
    width: 20px;
  }
  .title{
    border-bottom: 1px solid $greyMid;
  }
  
}