@import "../PageDashboard.scss";
@import "../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";

.detail-form {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .display-item {
    border-bottom: 1px solid $spn-050-color;
  }

  .title {}

  .desc {
    text-align: left;
    margin: 16px 0 40px 0;
    @include text(400, 16px, 16px, $black);
  }
}

.payout-country-form {
  .country-radio {
    .form-group {
      display: flex;
      align-items: center;

      .label-radio {
        margin-top: 10px;
        margin-right: 10px;
      }
    }

  }
}

.payout-container {
  .payout-action {
    display: flex;
    align-items: center;

    .edit-icon {
      margin-left: 10px;
      @include text(500, 15px, 16px, $black);
      cursor: pointer;
    }
  }


  .MuiDialog-paperWidthSm {
    max-width: 1000px !important;
    min-width: 1000px !important
  }

  @include PageDashboard();

  .kyc-action {
    display: flex;
    align-items: center;

    .edit-icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .chart-title {
    display: flex;
    justify-content: space-between;
  }

  .kyc-process {
    letter-spacing: 0.4px;
    @include text(700, 18px, 16px, $black);
  }

  .select-option {
    display: flex;
  }

  .option:first-child {
    margin-right: 10px;
  }

  .option {
    width: 150px;
    cursor: pointer;
  }
}
.swift-modal-container{
  .MuiDialog-paperWidthMd {
    max-width: 490px !important;
}
}
.swift-detail-form{
  width: 400px;
  position: relative;
  padding-bottom: 100px;
  .detail-title{
    display: flex;
    justify-content: space-between;
    @include text(600, 20px, 16px, $black);
    border-bottom: 1px solid $greyMid;
    padding: 24px;
  }
  .title{
    @include text(600, 12px, 16px, $spn-500-color);
  }
  .desc{
    @include text(400, 16px, 16px, $spn-900-color);
    margin-top: 12px;
  }
  .display-item{
    border-bottom: 1px solid $spn-050-color;
  }
  .display-item:last-child{
    border-bottom: none;
    margin-bottom: 50px;
  }
.update-btn{
  border-top: 1px solid $greyMid;
  width:inherit;
    max-width:inherit;
  padding: 24px;
  position: fixed;
  bottom: 0;
  background-color: $white;
  button:first-child {
    width: 100%;
  }
  .primary-btn{
    background-color: $lightBlue;
    border: none
  }
}

}
.view-more{
  @include text(600, 14px, 16px, $lightBlue);
}