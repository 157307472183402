@import "../PageDashboard.scss";
@import "../PageDashboard.scss";
@import "../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";

.virtual-account-container {
  @include PageDashboard();
  .go-back {
    cursor: pointer;
    margin: 24px 0 16px 0;
   @include text(600, 14px, 16px, $lightBlue);
  }
}