@import "../PageDashboard.scss";
@import "../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";

.collect-account-container {
  @include PageDashboard();
}

.manage-collect-merchant{
  margin: 10px;
  .manage-title{
    display: inline-block;
    margin-left: 16px;
    @include text(600, 16px, 24px, $spn-700-color);
  }
  .manage-merchant {
    margin-top: 10px;
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 24px 14px 16px;
    background: $white;
    border: 1px solid $offWhite;
    border-radius: 5px;
    cursor: pointer;
    .manage-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: rgba(23, 95, 255, 0.1);
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
  .goback-btn, .close-btn {
    width: 100%;
    background-color: $lightBlue;
    color: $white;
    height: 40px;
  }
  .close-btn {
    background-color: $lightBlue;
    color: $white;
    margin-top: 32px;
  }
  .goback-btn{
    background-color: $white;
    color: $spn-500-color;
    margin-top: 10px;
    border: none;
  }
}
.manage-collect-content, .create-collect-form {
  min-width: 500px;
}