@import "../assets/common/mixins.scss";
@import "../assets/common/colors.scss";

@mixin PageDashboard() {
  .capitalise {
    text-transform: capitalize;
  }
  .tab-items {
    padding: 16px 0 24px 0;
    background: $white;
    border: 1px solid $spn-110-color;
    border-radius: 3px;
  }
  .service-name {
    @include text(700, 24px, 16px, $spn-900-color);
    margin-bottom: 25px;
  }
  .service-items {
    display: flex;
    .item {
      @include text(500, 14px, 16px, $spn-500-color);
      margin-right: 24px;
      padding-bottom: 18px;
      cursor: pointer;
    }
    .active {
      @include text(600, 14px, 16px, $lightBlue);
      letter-spacing: 0.002em;
      border-bottom: 2px solid $lightBlue;
      padding-bottom: 18px;
      cursor: pointer;
    }
    border-bottom: 1px solid $offWhite;
    margin-bottom: 24px;
  }
  .approve {
    @include text(600, 14px, 16px, $lightBlue);
    letter-spacing: 0.002em;
    margin-right: 24px;
  }
  .reject {
    @include text(600, 14px, 16px, $spr-500-color);
    letter-spacing: 0.002em;
  }
  .customScroll {
    margin-bottom: 15px;
    padding: 0 16px;
  }
  .status-flag {
    display: flex;
    align-items: center;
    .dot-status {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
    }
    .dot-active {
      background: $spg-700-color;
    }
    .dot-inactive {
      background: $error;
    }
    .dot-pending {
      background: $spn-110-color;
    }
  }
  .export-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border: 1px solid $spn-110-color;
    border-radius: 3px;
    background: $white;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
  }
  .download-transaction {
    @include text(600, 14px, 16px, $primaryColor);
    letter-spacing: 0.002em;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
  .create-btn {
    margin-top: 5px;
  }
  .paginate-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-left: 1rem;
  }
}
.create-form {
  min-width: 400px;
  label {
    margin-top: 18px;
    @include text(400, 14px, 16px, $primaryColor);
    letter-spacing: 0.4px;
  }

  .error-desc {
    @include text(400, 12px, 16px, $error);
    letter-spacing: 0.4px;
  }
  .field-msg{
    margin-top: 5px;
   @include text(400, 12px, 16px, $spn-900-color);
  }
}
