// @import "../../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";

.pagination-container{
  display: flex;
  align-items: center;
      .pagination-div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .page-desc-div{
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 121px;
      color:gray;  
    } 
    
    .page-number-div{
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: gray;
    margin: 0 25px 0 24px;
    cursor: pointer;
  }
  .active{
    width: 23px;
height: 24px;
background: $lightBlue;
border-radius: 2px;
  color:  white;
  margin: 0 14px 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  }
    .arrow{
      width: 28px;
      height: 28px;
      background: white;
      box-shadow: 0px 0.904708px 7.23767px rgba(23, 27, 30, 0.2);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items:center;
      cursor: pointer;
      color: gray;
    }

    .arrow-icon {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #24292E;
    }

    @media(max-width:768px){
      .page-desc-div{
          margin-bottom: 61px;
      }
      .page-number-div{
        margin: 0 15px 0 14px;
      }
    }
    
}