@import "../../PageDashboard.scss";
@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/colors.scss";

.card-body{
    padding: 0;
}
.lookup-container{
    background-color: $white;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    .no-record {
        display: flex;
        justify-content: center;
    }
    .account-lookup{
        border-bottom: 1px solid $spn-050-color;
        padding: 24px 24px 18px 24px;
    }
    label{
        margin: 14px 0 8px 0;
    }
    .reference {
        @include text(400, 16px, 16px, $black);

    }
    .search-btn, .reverse-btn, .retry-btn {
        height: 40px;
        width: 100%;
        border-radius: 4px;
        border: none;
        margin-top: 24px;
        color: $white;
    }
    .search-btn, .retry-btn {
        background-color: $spb-600-color;
    }
    .reverse-btn {
        width: 112px;
    }
    .retry-btn {
        width: 91px;
    }
    .reverse-btn {
        margin-right: 16px;
        background-color: $spr-500-color;
    }
    .action-btn {
        display: flex;
        justify-content: flex-end;
    }
    .form-content{
        padding: 0 24px 24px 24px;
    }
    .content{
        border-left: 1px solid $spn-050-color;
        padding: 24px;
        .search-result{
          @include text(600, 20px, 16px, $black);
          margin-bottom: 24px;
        }
        .result{
            padding: 16px 24px 1px 24px;
            background-color: $spn-050-color;
            margin-bottom: 24px;
            border-radius: 8px;
            .details{
              @include text(900, 18px, 16px, $black);
              margin-bottom: 24px;

            }
            .item{
                @include text(600, 14px, 16px, $black);
                margin-bottom: 24px;
                span{
                    @include text(500, 14px, 16px, $black);
                    margin-bottom: 24px;
                  }
              }
        }
    }
}