@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/colors.scss";

.table-wrap {
  border: 1px solid $spn-110-color;
  border-radius: 3px;

  .content-div {
    overflow-y: "hidden";
  }

  .amt-label {
    @include text(500, 14px, 16px, $spn-500-color);
    letter-spacing: 0.002em;
  }

  .amt-value {
    @include text(700, 24px, 29px, $darkish);
    letter-spacing: 0.002em;
  }
}
.text-small {
  font-size: 0.75rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-1 {
  gap: 0.5rem;
}

.status {
  height: 8px;
  width: 8px;
  border-radius: 50%;

  &.processing {
    background: $spb-400-color;
  }
  &.success {
    background-color: $labelColor;
  }
  &.failed {
    background: $error;
  }
}
