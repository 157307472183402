/*
Remove up and down arrows from number input
*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-control:hover {
  border-color: rgba(0, 0, 0, 0.87) !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 4px !important;
}
.form-control:focus {
  border-color: #433a769e !important;
  border-style: solid !important;
  border-width: 2px !important;
  box-shadow: none;
}
.form-control is-invalid:focus {
  box-shadow: none;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #433a769e !important;
  border-style: solid !important;
  border-width: 2px !important;
  box-shadow: none;
}

.btn-xs {
  padding: 3px 7px;
  font-size: 12px;
}

.font-small {
  font-size: 1rem !important;
}

.gap-2 {
  gap: 1rem;
}

.custom-toast-container {
  width: 500px;
}
.cursor{
  cursor: pointer;
}