@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/colors.scss";

.merchant-header {
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
}

.subscription-container {
  text-align: left;
  background: $spn-150-color;
  border-radius: 5px;
  padding: 10px;
  .header {
    @include text(500, 14px, 14spx, $black);
  }
  .desc {
    @include text(700, 16px, 0px, $black);
  }
}
.opacity {
  opacity: 0.5;
  border: 1px solid rgb(33, 5, 128);
  outline: 1px solid rgb(4, 4, 115);
  border-radius: 5px;
}
