.merchant-header {
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
}

.margin-5 {
  margin: 0.5rem;
}

.opacity {
  opacity: 1;
}

.table_row {
  cursor: pointer;
}

.form-height {
  height: 30vh;
}
