@function color-yiq($color) {
    $r: red($color);
    $g: green($color);
    $b: blue($color);
    $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;
    @if ($yiq >= $yiq-contrasted-threshold) {
        @return $yiq-dark-color;
    } @else {
        @return $yiq-light-color;
    }
}
@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}
@function theme-color-level($color-name: "primary", $level: 0) {
    $color: theme-color($color-name);
    $color-base: if($level > 0, $yiq-dark-color, $yiq-light-color);
    $level: abs($level);
    @return mix($color-base, $color, $level * $theme-color-interval);
}
