/* Datepicker Styles override */
@import "./assets/common/colors.scss";
@import "./assets/common/mixins.scss";

@import "../node_modules/react-datepicker/dist/react-datepicker.css";

.react-datepicker {
  border: 1px solid $spn-050-color;
  .react-datepicker__header {
    background-color: $white;
    border-bottom: none;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    .react-datepicker__triangle::before {
      border-bottom-color: inherit !important;
      display: none;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: transparent;
  }

  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border: unset !important;
    border-width: 0px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $spb-900-color;
    line-height: 2.2rem;
    margin: 0.166rem;
    height: 35px;
    width: 35px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $blueish;
    color: $white;
    border-radius: 50%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: $blueish;
    color: $white !important;
  }
}
/* End Datepicker styles override */
