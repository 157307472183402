@import "../PageDashboard.scss";
@import "../PageDashboard.scss";
@import "../../assets/common/mixins.scss";
@import "../../assets/common/colors.scss";

.transfers-account-container {
  @include PageDashboard();
  .go-back {
    cursor: pointer;
    margin: 24px 0 16px 0;
   @include text(600, 14px, 16px, $lightBlue);
  }
  .action{
   @include text(600, 14px, 16px, $lightBlue);
  }
  
}
.view-transfer-success-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  .title{
    margin: 32px 0 24px 0;
    @include text(600, 20px, 16px, $black);
  }
  .desc{
    margin-bottom: 30px;
    @include text(400, 16px, 24px, $spn-900-color);
  }
  .btn {
    width: 100%;
  }
  .close-btn{
    max-width: 100% !important;
    min-width: 100% !important;
    background-color: $lightBlue;
    border: none
  }
}
.select-dropdown-mb{
  margin-bottom: 120px;
}
